import { createSlice } from "@reduxjs/toolkit";

import { juriDocumentData } from "../actions/action";

const juriDocumentSlice = createSlice({
  name: "juriDocuments",
  initialState: {
    loading: false,
    juriDocuments: {},
  },
  extraReducers: (builder) => {
    builder.addCase(juriDocumentData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(juriDocumentData.fulfilled, (state, action) => {
      state.loading = false;
      state.juriDocuments = action.payload;
      state.error = "";
    });
    builder.addCase(juriDocumentData.rejected, (state, action) => {
      state.loading = false;
      state.juriDocuments = [];
      state.error = action.error.message;
    });
  },
});

export default juriDocumentSlice.reducer;
