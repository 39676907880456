import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import { globalActualityData } from "../redux/actions/action";
import SimpleCard from "../components/SimpleCard";

const Actuality = () => {
  let actuality = useSelector((state) => state.globalActuality);
  const dispatch = useDispatch();

  let actualities = actuality?.globalActualities?.data;

  useEffect(() => {
    dispatch(globalActualityData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Actualités" />
      <div className="container">
       <SimpleCard data={actualities} />
      </div>
      <NewLetter />
      <Footer />
    </>
  );
};

export default Actuality;
