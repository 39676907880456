import React from "react";
import "../App.css";
import "../theme.css";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const Pagination = () => {
  return (
    <div className="container pt-7">
      <div className="paginate d-flex align-item-center justify-content-between">
        <div className="left">
          <a className="btn-left btn">
            <FaChevronLeft />
            Précédent
          </a>
        </div>
        <div className="center">
          <span>100 sur 100</span>
        </div>
        <div className="right">
          <a className="btn-right btn">
            Suivant
            <FaChevronRight />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
