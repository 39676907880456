import { createSlice } from "@reduxjs/toolkit";

import { ministerBiographyData } from "../actions/action";

const ministerBiographySlice = createSlice({
  name: "ministerBiography",
  initialState: {
    loading: false,
    ministerBiographies: {},
  },
  extraReducers: (builder) => {
    builder.addCase(ministerBiographyData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ministerBiographyData.fulfilled, (state, action) => {
      state.loading = false;
      state.ministerBiographies = action.payload;
      state.error = "";
    });
    builder.addCase(ministerBiographyData.rejected, (state, action) => {
      state.loading = false;
      state.ministerBiographies = [];
      state.error = action.error.message;
    });
  },
});

export default ministerBiographySlice.reducer;
