import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import { documentData } from "../redux/actions/action";

const Documentation = () => {
  let document = useSelector((state) => state.document);
  const dispatch = useDispatch();

  let documents = document?.documents?.data;

  console.log("document: ", documents);

  useEffect(() => {
    dispatch(documentData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Documentation" />
      <ExtraCard data={documents} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Documentation;
