import React from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const NewLetter = () => {
  return (
    <section className="newsletter pb-11">
      <div className="container">
        <div className="row mt-7">
          <div className="col-lg-12 text-center">
            <span className="mb-6">Ne ratez aucune information</span>
            <h2>Abonnez-vous à notre newsletter</h2>
          </div>
          <div className="col-lg-10 col-xl-10 mx-auto">
              <Row className="formular">
                <Col xs={5} className="box-control">
                  <Form.Control type="text" placeholder="Nom et prénom" className="w-100" />
                </Col>

                <Col xs={5} className="box-control">
                  <Form.Control type="email" placeholder="Email" className="w-100" />
                </Col>

                <Col xs={2} className="text-end">
                  <Button type="submit">
                    S'abonner
                  </Button>
                </Col>
              </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewLetter;
