import React from "react";
import { FaLink } from "react-icons/fa";
import { useLocation } from "react-router-dom";

import Pagination from "./Pagination";

import { truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

const MediumCard = ({ data }) => {
  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/reformes") ||
      path.startsWith("/platformes") ||
      path.startsWith("/projet-encour-de-realisation")
    ) {
      return <Pagination />;
    }
  };

  return (
    <div className="container py-11">
      <div className="row">
        {data?.length === 0 && (
          <p className="text-center">
            Aucune donnée disponible pour le moment. Veuillez réessayer plus
          </p>
        )}
        {data?.map((item, index) => (
          <div className="col-lg-4 mb-4" key={index}>
            <div className="platform">
              <div className="grow-content">
                <h2>{item.name}</h2>
                <p>{truncate(item.description, 150)}</p>
                <a
                  className="link"
                  href={`https://${item.url}`}
                  target="_blank"
                >
                  <FaLink /> Aller sur le site
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      {data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default MediumCard;
