import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import AgendaCard from "../components/CardAgenda";

import { agendaData } from "../redux/actions/action";

const Agenda = () => {
  let agenda = useSelector((state) => state.agenda);
  const dispatch = useDispatch();

  console.log("economiquePromotion: ", agenda);

  let agendas = agenda?.agendas?.data;

  useEffect(() => {
    dispatch(agendaData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Agenda" />
      <AgendaCard data={agendas} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Agenda;
