export const limitTextLines = (text, lines) => {
  const words = text.split(" ");

  let newText = "";
  let lineCount = 0;

  for (let i = 0; i < words.length; i++) {
    newText += words[i] + " ";
    lineCount = Math.ceil((newText.length * 8) / window.innerWidth);
    if (lineCount >= lines) {
      return newText.trim() + "...";
    }
  }

  return newText.trim();
};

export const formatDate = (dateString) => {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
};

export const formatSimpleDate = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
};

export const dateFormat = (dateString) => {
  const date = new Date(dateString);

  const mois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const jour = date.getDate();
  const moisIndex = date.getMonth();
  const annee = date.getFullYear();

  const dateFormatee = `${jour} ${mois[moisIndex].toUpperCase()} ${annee}`;

  return dateFormatee;
};

export  const truncate = (str, n) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};
