import React from "react";
import { useLocation } from "react-router-dom";
import { Share, MapFill, ClockFill } from "react-bootstrap-icons";
import { FaChevronRight } from "react-icons/fa";

import Pagination from "./Pagination";

import { truncate, dateFormat } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

const AgendaCard = ({ data }) => {
  const location = useLocation();
  const path = location.pathname;

  console.log("data: ", data);

  let renderPagination = () => {
    if (path.startsWith("/agenda-du-ministre") || path.startsWith("/agenda")) {
      return <Pagination />;
    }
  };

  return (
    <div className="container pt-11">
      <div className="row">
        {data?.length === 0 && (
          <p className="text-center pt-11">
            Aucune donnée disponible pour le moment. Veuillez réessayer plus
          </p>
        )}
        {data?.map((item, index) => {
          let image = item?.media?.find(
            (media) =>
              media.mime_type === "image/jpeg" ||
              media.mime_type === "image/png"
          );

          let renderShareBlock = () => {
            if (!path.startsWith("/agenda-du-ministre")) {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    <Share />
                  </span>
                  <span>
                    <a className="link-agenda" href="#">
                      Lire la plus <FaChevronRight />
                    </a>
                  </span>
                </div>
              );
            }
          };

          let renderLocationBlock = () => {
            if (path.startsWith("/agenda-du-ministre")) {
              return <span>Lieu {item?.location}</span>;
            }
          };

          let renderSimpleLocationBlock = () => {
            if (path === "/agenda") {
              return (
                <span>
                  <MapFill className="mr-2" />
                  {item?.location}
                </span>
              );
            }
          };

          let renderDescriptionBlock = () => {
            if (path.startsWith("/agenda-du-ministre")) {
              return <p>{truncate(item?.description, 100)}</p>;
            }
          };

          return (
            <div className="col-lg-4 mb-4" key={index}>
              <div className="agenda-box">
                <img
                  src={image?.original_url}
                  height="30"
                  className="img-fluid w-100 pb-2"
                  alt="Project"
                />
                {renderSimpleLocationBlock()}
                <h3 className="py-2">
                  {!path.startsWith("/agenda-du-ministre") && (
                    <ClockFill className="mr-2" />
                  )}
                  Du {dateFormat(item?.start_date)} au{" "}
                  {dateFormat(item?.end_date)}
                </h3>
                <h5>{truncate(item?.title, 35)}</h5>
                {renderLocationBlock()}
                {renderDescriptionBlock()}
                {renderShareBlock()}
              </div>
            </div>
          );
        })}
      </div>
      {data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default AgendaCard;
