import { createSlice } from "@reduxjs/toolkit";

import { agendaData } from "../actions/action";

const agendaSlice = createSlice({
  name: "agendas",
  initialState: {
    loading: false,
    agendas: {},
  },
  extraReducers: (builder) => {
    builder.addCase(agendaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(agendaData.fulfilled, (state, action) => {
      state.loading = false;
      state.agendas = action.payload;
      state.error = "";
    });
    builder.addCase(agendaData.rejected, (state, action) => {
      state.loading = false;
      state.agendas = [];
      state.error = action.error.message;
    });
  },
});

export default agendaSlice.reducer;
