import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Tabs from "./Tabs";

import "../App.css";
import "../theme.css";

import SimpleCard from "./SimpleCard";
import ExtraCard from "./ExtraCard";

const GlobalCard = ({ documentData, textData }) => {
  const [activeTab, setActiveTab] = useState("texte");

  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <section className="card-current">
      <div className="container">
        <Row>
          <div className="col-lg-3 mx-auto mt-7">
            <Tabs activeTab={activeTab} handleTabChange={handleTabChange} />
          </div>
        </Row>

        <Row>
          {activeTab === "texte" ? (
            <SimpleCard data={textData} />
          ) : (
            <Row>
              <ExtraCard data={documentData} />
            </Row>
          )}
        </Row>
      </div>
    </section>
  );
};

export default GlobalCard;
