import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import { truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

const ArchiveCard = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  console.log("data: ", data);

  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (path.match("/archive-du-ministere")) {
      return <Pagination />;
    }
  };

  return (
    <div className="container">
      {cardDetails === null && (
        <div className="row grow mt-7 pt-7">
          {data?.length === 0 && (
            <p className="text-center">
              Aucune donnée disponible pour le moment. Veuillez réessayer plus
            </p>
          )}
          {data?.map((elemt, index) => {
            let imageUrl = elemt?.media?.find(
              (media) =>
                media.mime_type === "image/jpeg" ||
                media.mime_type === "image/png" ||
                media.mime_type === "image/jpg"
            );
            return (
              <div className="col-lg-4 mb-4" key={index}>
                <div className="archive-box">
                  <img
                    src={imageUrl?.original_url}
                    className="img-fluid w-100 pb-2"
                    alt=""
                  />
                  <h3 className="py-2">
                    {elemt.firstname + " " + elemt.lastname}
                  </h3>
                  <p>{truncate(elemt.poste, 35)}</p>
                  <a
                    className="link-archive"
                    onClick={() => setCardDetails(elemt)}
                    role="button"
                  >
                    Lire la suite <FaChevronRight />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </div>
  );
};

export default ArchiveCard;
