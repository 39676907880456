import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { reformeData } from "../redux/actions/action";

const Reform = () => {
  let reform = useSelector((state) => state.reforme);
  const dispatch = useDispatch();

  let reforms = reform?.reformes?.data;
  let reformText = reforms?.filter((reform) => reform.type === "text");

  let reformDocument = reforms?.filter((reform) => reform.type === "document");

  useEffect(() => {
    dispatch(reformeData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des Réformes" />
      <GlobalCard textData={reformText} documentData={reformDocument} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Reform;
