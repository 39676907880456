import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { repportData } from "../redux/actions/action";

const Repport = () => {
  let repport = useSelector((state) => state.repport);
  const dispatch = useDispatch();

  let repports = repport.repports.data;

  console.log("repports: ", repports);

  let repportsDocument = repports?.filter((item) => item.type === "document");

  let repportsText = repports?.filter((item) => item.type === "text");

  useEffect(() => {
    dispatch(repportData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Rapports d’activités" />
      <GlobalCard textData={repportsText} documentData={repportsDocument} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Repport;
