import { createSlice } from "@reduxjs/toolkit";

import { managmentUnderSupervisionData } from "../actions/action";

const currentSituationSlice = createSlice({
  name: "managmentUnderSupervisions",
  initialState: {
    loading: false,
    managmentUnderSupervisions: {},
  },
  extraReducers: (builder) => {
    builder.addCase(managmentUnderSupervisionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      managmentUnderSupervisionData.fulfilled,
      (state, action) => {
        state.loading = false;
        state.managmentUnderSupervisions = action.payload;
        state.error = "";
      }
    );
    builder.addCase(managmentUnderSupervisionData.rejected, (state, action) => {
      state.loading = false;
      state.managmentUnderSupervisions = [];
      state.error = action.error.message;
    });
  },
});

export default currentSituationSlice.reducer;
