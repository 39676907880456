import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import SingleDetailPage from "../components/SingleDetailsPage";

import { aboutUsData } from "../redux/actions/action";

const AboutUs = () => {
  let location = useLocation();
  let path = location.pathname;

  let aboutUs = useSelector((state) => state.aboutUs);
  const dispatch = useDispatch();

  let aboutUsDataInfo = aboutUs?.aboutUs?.data;

  useEffect(() => {
    dispatch(aboutUsData());
  }, [dispatch]);

  const getTitle = () => {
    if (path.startsWith("/visions")) {
      return "Visions";
    } else if (path.startsWith("/missions-du-ministere")) {
      return "Missions";
    } else if (path.startsWith("/objectifs")) {
      return "Objectifs";
    }
    return "";
  };

  return (
    <>
      <Navbar />
      <BannerCurrent title={getTitle()} />
      <SingleDetailPage data={aboutUsDataInfo} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default AboutUs;
