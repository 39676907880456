import React, { useState } from "react";
import "../App.css";
import "../theme.css";

import HomeImage from "../assets/images/accueil/accueil_plateforme_mten.jpg";

const Banner = () => {
  const [imageSrc] = useState(HomeImage);

  return (
    <section
      className="banner d-flex align-items-center py-11"
      style={{ backgroundImage: `url(${imageSrc})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="text-banner">
              <h1>
                L'essentiel à savoir sur le développement du numérique au Tchad
              </h1>
            </div>
            <div className="btn-banner">Lire l'article</div>
          </div>
          <div className="col-lg-7"></div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
