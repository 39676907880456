import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumExtraCard from "../components/MediumExtraCard";

import { sensibilisationData } from "../redux/actions/action";

const Sensibilisation = () => {
  let sensibilisation = useSelector((state) => state.sensibilisation);
  const dispatch = useDispatch();

  console.log("opportunity: ", sensibilisation);

  let sensibilisations = sensibilisation?.sensibilisations?.data;

  useEffect(() => {
    dispatch(sensibilisationData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Sensibilisation" />
      <MediumExtraCard data={sensibilisations} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Sensibilisation;
