import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import { formatDate, truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

import SensibilityImg from "../assets/images/documentation.png";

const MediumExtraCard = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (
      path.startsWith("/promotion-economique-numerique") ||
      path.startsWith("/sensibilisation")
    ) {
      return <Pagination />;
    }
  };

  return (
    <div className="container py-11">
      {cardDetails === null && (
        <div className="row">
          {data?.length === 0 && (
            <p className="text-center">
              Aucune donnée disponible pour le moment. Veuillez réessayer plus
            </p>
          )}
          {data?.map((item, index) => (
            <>
              <div className="col-lg-6 mb-4" key={index}>
                <div className="sensibility">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      <img src={SensibilityImg} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-8">
                      <span>{formatDate(item.created_at)}</span>
                      <h2>{item.title}</h2>
                      <p>{truncate(item.description, 125)}</p>
                      <a
                        className="link-fill"
                        onClick={() => setCardDetails(item)}
                        role="button"
                      >
                        Lire <FaChevronRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="sensibility">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      <img src={SensibilityImg} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-8">
                      <span>{formatDate(item.created_at)}</span>
                      <h2>{item.title}</h2>
                      <p>{truncate(item.description, 125)}</p>
                      <a
                        className="link-fill"
                        onClick={() => setCardDetails(item)}
                        role="button"
                      >
                        Lire <FaChevronRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </div>
  );
};

export default MediumExtraCard;
