import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { organismAndFonctionalityData } from "../redux/actions/action";

const OrganisationAdFonctionality = () => {
  let organisationAndFonctionality = useSelector(
    (state) => state.organismAndFonctionality
  );
  const dispatch = useDispatch();

  console.log("organisationAndFonctionality: ", organisationAndFonctionality);

  let organasationAndFonctionalities =
    organisationAndFonctionality?.organismAndFonctionalities?.data;

  let organasationAndFonctionalityText = organasationAndFonctionalities?.filter(
    (observatoire) => observatoire.type === "text"
  );

  let organasationAndFonctionalityDocument =
    organasationAndFonctionalities?.filter(
      (observatoire) => observatoire.type === "document"
    );

  useEffect(() => {
    dispatch(organismAndFonctionalityData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Organisations et fonctionnements" />
      <GlobalCard
        documentData={organasationAndFonctionalityDocument}
        textData={organasationAndFonctionalityText}
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default OrganisationAdFonctionality;
