import { createSlice } from "@reduxjs/toolkit";

import { plateformeData } from "../actions/action";

const plateformeSlice = createSlice({
  name: "plateformes",
  initialState: {
    loading: false,
    plateformes: {},
  },
  extraReducers: (builder) => {
    builder.addCase(plateformeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(plateformeData.fulfilled, (state, action) => {
      state.loading = false;
      state.plateformes = action.payload;
      state.error = "";
    });
    builder.addCase(plateformeData.rejected, (state, action) => {
      state.loading = false;
      state.plateformes = [];
      state.error = action.error.message;
    });
  },
});

export default plateformeSlice.reducer;
