import { createSlice } from "@reduxjs/toolkit";

import { entitiesData } from "../actions/action";

const entitiesSlice = createSlice({
  name: "entities",
  initialState: {
    loading: false,
    entities: {},
  },
  extraReducers: (builder) => {
    builder.addCase(entitiesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(entitiesData.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;
      state.error = "";
    });
    builder.addCase(entitiesData.rejected, (state, action) => {
      state.loading = false;
      state.entities = [];
      state.error = action.error.message;
    });
  },
});

export default entitiesSlice.reducer;
