import { createSlice } from "@reduxjs/toolkit";

import { tchadEcosystemData } from "../actions/action";

const tchadEcosystemSlice = createSlice({
  name: "aboutUs",
  initialState: {
    loading: false,
    tcadEcosystem: {},
  },
  extraReducers: (builder) => {
    builder.addCase(tchadEcosystemData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(tchadEcosystemData.fulfilled, (state, action) => {
      state.loading = false;
      state.tcadEcosystem = action.payload;
      state.error = "";
    });
    builder.addCase(tchadEcosystemData.rejected, (state, action) => {
      state.loading = false;
      state.tcadEcosystem = [];
      state.error = action.error.message;
    });
  },
});

export default tchadEcosystemSlice.reducer;
