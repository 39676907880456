import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumCard from "../components/MediumCard";

import "../App.css";
import "../theme.css";

import { plateformeData } from "../redux/actions/action";

const Plateforme = () => {
  let plateform = useSelector((state) => state.plateforme);
  const dispatch = useDispatch();

  let plateforms = plateform?.plateformes?.data;

  console.log("plateforms: ", plateforms);

  useEffect(() => {
    dispatch(plateformeData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Plateformes" />
      <MediumCard data={plateforms} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Plateforme;
