import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import SimpleCard from "../components/SimpleCard";

import { startupData } from "../redux/actions/action";

const StartUp = () => {
  let startup = useSelector((state) => state.startup);
  const dispatch = useDispatch();

  let startups = startup.startups.data;

  React.useEffect(() => {
    dispatch(startupData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Startups" />
      <div className="container">
        <SimpleCard data={startups} />
      </div>
      <NewLetter />
      <Footer />
    </>
  );
};

export default StartUp;
