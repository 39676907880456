//About us
export const GET_ABOUT_US_DATA = "/api/v1/about";

//Author
export const GET_ACTOR_DATA = "/api/v1/acteurs/findlatest";

//CurrentSituation
export const GET_CURRENT_SITUATION_DATA = "/api/v1/etats-des-lieux-listes";

//Actuality
export const GET_ACTUALITY_DATA = "/api/v1/actualites/findninelatest";
export const GET_THREE_ACTUALITY_DATA = "/api/v1/actualites/findthreelatest";
export const GET_HOME_ACTUALITY_DATA = "/api/v1/actualites-home";
export const GET_GLOBAL_ACTUALITY_DATA = "/api/v1/actualites-slide-home";

//Project
export const GET_PROJECT_DATA = "/api/v1/projets/projet-realise";
export const GET_LATESTPROJECT_DATA = "/api/v1/projets-latest";
export const GET_RECENT_PROJECT_DATA = "/api/v1/projets-recent";
export const GET_GLOBAL_PROJECT_DATA = "/api/v1/projet-category";

//Reforme
export const GET_REFORME_DATA = "/api/v1/reformes-listes";

//Plateforme
export const GET_PLATEFORME_DATA = "/api/v1/plateformes-latest";
export const GET_PLATEFORME_LIST_DATA = "/api/v1/plateformes-list";

//Agenda
export const GET_AGENDA_DATA = "/api/v1/agendas/ministere-latest";
export const GET_AGENDA_LISTE_DATA = "/api/v1/agendas-ministere-listes";

//Archive
export const GET_ARCHIVE_DATA = "/api/v1/ministres/archives";

//Orgnigram
export const GET_ORGNIGRAM_DATA = "/api/v1/organigrame";

//Orgnism
export const GET_ORGNISM_AND_FONCTIONALITY_DATA =
  "/api/v1/organisations-fonctionnements";

//ManagmentUnderSupervision
export const GET_MANAGMENT_UNDER_SUPERVISION_DATA =
  "/api/v1/directions/findforlatest";

//entite
export const GET_ENTITE_DATA = "/api/v1/entites-latest";

//plan
export const GET_STRATEGIQUE_PLAN_DATA =
  "/api/v1/projets/plan-strategique-2030";
export const GET_ACTION_PLAN_DATA = "/api/v1/projets/plan-action-2024";
export const GET_REALISE_PROJECT_DATA = "/api/v1/projets/projet-realise";

//Rapport
export const GET_REPPORT_DATA = "/api/v1/rapports-latest";
export const GET_REPPORT_LIST_DATA = "/api/v1/rapports-listes";

//Document
export const GET_DOCUMENT_DATA = "/api/v1/documents-latest";
export const GET_DOCUMENT_LIST_DATA = "/api/v1/documents-liste";

//Opportunity
export const GET_OPPORTUNITY_DATA = "/api/v1/opportunites-latest";
export const GET_OPPORTUNITY_LIST_DATA = "/api/v1/opportunites-listes";

//Startup
export const GET_STARTUP_DATA = "/api/v1/startups-latest";
export const GET_STARTUP_LIST_DATA = "/api/v1/startups-listes";

//Promotion
export const GET_PROMOTION_DATA = "/api/v1/promotions-latest";

//Promotion Economique
export const GET_PROMOTION_ECONOMIQUE_DATA = "/api/v1/promotions-listes";

//Sensitisation
export const GET_SENSIBILISATION_DATA = "/api/v1/sensibilisations-latest";
export const GET_SENSIBILISATION_LIST_DATA = "/api/v1/sensibilisations-listes";

//Formation
export const GET_FORMATION_DATA = "/api/v1/formations-latest";
export const GET_FORMATION_LIST_DATA = "/api/v1/formations-listes";

//JuriDocument
export const GET_JURI_DOCUMENT_DATA = "/api/v1/document-juridiques-category";
export const GET_JURI_CATEGORY_DOCUMENT_DATA = `/api/v1/document-juridiques`;

//Chad Ecosystem
export const GET_CHAD_ECO_DATA = "/api/v1/ecosystemes-du-tchad";

//Ministre
export const GET_MINISTER_BIOGRAPHY_DATA = "/api/v1/mot-biographie-ministre";
export const GET_MINISTER_ARCHIVE_DATA = "/api/v1/ministres/archives";
export const GET_MINISTER_AGENDA_DATA = "/api/v1/agendas-ministres-listes";
export const GET_DIRECTION_UNDER_TUTELLE_DATA = "/api/v1/directions/listes";
