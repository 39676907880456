import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const ActionPlan2024 = () => {
  let actionPlan = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();

  console.log("strategiquePlan: ", actionPlan);

  let strategiquePlans = actionPlan?.globalProjects?.data;

  // let strategiquePlansDocument = strategiquePlans?.filter(
  //   (item) => item.media.length === 0
  // );

  // let strategiquePlansText = strategiquePlans?.filter(
  //   (item) => item.media.length > 0
  // );

  React.useEffect(() => {
    dispatch(globalProjectData("plan-action-2024"));
  }, [dispatch]);

  //plan-action-2024
  return (
    <>
      <Navbar />
      <BannerCurrent title="Plan d’action 2024" />
      <GlobalCard textData={strategiquePlans} documentData={strategiquePlans} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default ActionPlan2024;
