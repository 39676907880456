import React from "react";
import { useLocation } from "react-router-dom";
import { Instagram, Facebook, Twitter, Linkedin } from "react-bootstrap-icons";

import { formatDate } from "../helpers/global_helper";
import {
  RenderImage,
  RenderPeridoBlock,
  RenderShareBlock,
  RenderPublicationBlock,
  RenderText,
  RenderAuthorBlock,
  RenderSecteurBlock,
} from "../utils/const";

import "../App.css";
import "../theme.css";

const SingleDetailPage = ({ data }) => {
  console.log("data: ", data);

  const location = useLocation();
  const path = location.pathname;

  const dataImage = data?.media?.find(
    (media) =>
      media.mime_type === "image/jpeg" ||
      media.mime_type === "image/png" ||
      media.mime_type === "image/jpg"
  );

  return (
    <div className="container pt-11">
      <div className="row ">
        <div className="offset-1 col-lg-9 mx-auto">
          <div className="bloc-detail">
            {path.startsWith("/actualites") && (
              <h1 className="text-start">{data?.title}</h1>
            )}
            {path.startsWith("/actualites") && (
              <h5 className="text-start">
                <span>Publié le</span>
                {formatDate(data?.created_at)}
              </h5>
            )}
            {path.startsWith("/actualites") && (
              <div className="category pt-4 pb-4">
                <ul>
                  {data?.tags?.map((tag, index) => (
                    <li key={index}>
                      <a href="#">{tag?.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {RenderImage(dataImage)}
            <h1
              className={`${
                (path.startsWith("/biographie") ||
                  path.startsWith("/mot-du-ministre") ||
                  path.startsWith("/archive-du-ministere") ||
                  path.startsWith("/directions-sous-tutelles") ||
                  path.startsWith("/rapport-activites") ||
                  path.startsWith("/opportunites") ||
                  path.startsWith("/promotion-economique-numerique")) &&
                "text-start pb-4 pt-4"
              }`}
            >
              {(path.startsWith("/ecosysteme-du-tchad") ||
                path.startsWith("/etat-des-lieux") ||
                path.startsWith("/reformes") ||
                path.startsWith("/organisation-et-fonctionnement") ||
                path.startsWith("/plan-action-2024") ||
                path.startsWith("/plan-strategique-2030") ||
                path.startsWith("/projets-realises") ||
                path.startsWith("/projets-realises") ||
                path.startsWith("/projet-encour-de-realisation") ||
                path.startsWith("/rapport-activites") ||
                path.startsWith("/opportunites") ||
                path.startsWith("/start-up") ||
                path.startsWith("/formation") ||
                path.startsWith("/sensibilisation") ||
                path.startsWith("/promotion-economique-numerique")) &&
                data?.title}
              {path.startsWith("/directions-sous-tutelles") && data?.name}
              {(path.match("/biographie") ||
                path.match("/archive-du-ministere") ||
                path.match("/mot-du-ministre")) &&
                data?.firstname + " " + data?.lastname}
              {RenderText()}
            </h1>
            {!path.match("/biographie") && (
              <div className="d-flex align-items-center justify-content-between py-2">
                {RenderAuthorBlock(data)}
                {RenderPublicationBlock(data)}
              </div>
            )}
            {!path.match("/biographie") && (
              <div className="d-flex align-items-center justify-content-between py-2">
                {RenderPeridoBlock(data)}
                {RenderSecteurBlock(data)}
              </div>
            )}
            {!(
              path.startsWith("/ecosysteme-du-tchad") ||
              path.startsWith("/actualites") ||
              path.startsWith("/rapport-activites") ||
              path.startsWith("/opportunites") ||
              path.startsWith("/start-up") ||
              path.startsWith("/formation") ||
              path.startsWith("/sensibilisation") ||
              path.startsWith("/organisation-et-fonctionnement") ||
              path.startsWith("/promotion-economique-numerique")
            ) && (
              <div className="d-flex align-items-center justify-content-between py-2">
                <h5>
                  {(path.startsWith("/biographie") ||
                    path.startsWith("mot-du-ministre") ||
                    path.startsWith("/archive-du-ministere")) &&
                    data?.poste}
                  <>
                    <strong>Directeur Général :</strong>{" "}
                    {path.match("/directions-sous-tutelles") &&
                      data?.director_name}
                  </>
                  {(path.startsWith("/visions") ||
                    path.startsWith("/missions-du-ministere") ||
                    path.startsWith("/objectifs")) &&
                    "A ce titre il a la responsabilité des actions suivantes :"}
                </h5>
              </div>
            )}
            {(path.startsWith("/biographie") ||
              path.startsWith("/archive-du-ministere")) && (
              <div className="col-2 d-flex align-items-center justify-content-between py-2">
                <Instagram style={{ color: "#fccc63" }} />
                <Twitter style={{ color: "#1DA1F2" }} />
                <Facebook style={{ color: "#4267B2" }} />
                <Linkedin style={{ color: "#0e76a8" }} />
              </div>
            )}
            {path.startsWith("/actualites") && (
              <h1 className="text-start">{data?.description}</h1>
            )}
            <div className="text">
              <p>
                {(path.startsWith("/ecosysteme-du-tchad") ||
                  path.startsWith("/etat-des-lieux") ||
                  path.startsWith("/reformes") ||
                  path.startsWith("/directions-sous-tutelles") ||
                  path.startsWith("/organisation-et-fonctionnement") ||
                  path.startsWith("/plan-strategique-2030") ||
                  path.startsWith("/plan-action-2024") ||
                  path.startsWith("/projets-realises") ||
                  path.startsWith("/projet-encour-de-realisation") ||
                  path.startsWith("/actualites") ||
                  path.startsWith("/rapport-activites") ||
                  path.startsWith("/formation") ||
                  path.startsWith("/sensibilisation") ||
                  path.startsWith("/promotion-economique-numerique")) &&
                  data?.content}
                {(path.startsWith("/opportunites") ||
                  path.startsWith("/start-up")) &&
                  data?.description}
                {path.startsWith("/archive-du-ministere") && data?.biographie}
                {path.startsWith("/biographie") && data?.biographie}
                {path.startsWith("/mot-du-ministre") && data?.mot}
                {path.startsWith("/visions") && data?.vision}
                {path.startsWith("/missions-du-ministere") && data?.mission}
                {path.startsWith("/objectifs") && data?.objectif}
              </p>
            </div>
            {path.startsWith("/opportunites") && (
              <a
                className="link-fill btn btn-warning rounded-0 pt-3 pb-3"
                role="button"
              >
                TELECHARGER LE DOCUMENT
              </a>
            )}
          </div>
          {RenderShareBlock()}
        </div>
      </div>
    </div>
  );
};

export default SingleDetailPage;
