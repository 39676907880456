import { createSlice } from "@reduxjs/toolkit";

import { promotionData } from "../actions/action";

const promotionSlice = createSlice({
  name: "entities",
  initialState: {
    loading: false,
    promotions: {},
  },
  extraReducers: (builder) => {
    builder.addCase(promotionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(promotionData.fulfilled, (state, action) => {
      state.loading = false;
      state.promotions = action.payload;
      state.error = "";
    });
    builder.addCase(promotionData.rejected, (state, action) => {
      state.loading = false;
      state.promotions = [];
      state.error = action.error.message;
    });
  },
});

export default promotionSlice.reducer;
