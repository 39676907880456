import React from "react";
import "../App.css";
import "../theme.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const CardObservatory = () => {
  return (
    <Row className="mb-3">
      <Col>
        <Form.Group controlId="formGridSelect">
          <Form.Label>Type de document</Form.Label>
          <Form.Select className="w-100">
            <option className="text-white">Tous les documents</option>
            <option value="1">Documents 1</option>
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="formGridText">
          <Form.Label>Mots clés</Form.Label>
          <Form.Control type="text" placeholder="Saisissez les mots clés" className="w-100" />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="formGridDate">
          <Form.Label>Du</Form.Label>
          <Form.Control type="date" placeholder="aaa" className="w-100" />
        </Form.Group>
      </Col>
      
      <Col>
        <Form.Group controlId="formGridDate">
          <Form.Label>Au</Form.Label>
          <Form.Control type="date" placeholder="aaa" className="w-100" />
        </Form.Group>
      </Col>

      <Col className="text-end">
        <Form.Group controlId="formGridSubmit">
          <Button type="submit">
            Rechercher
          </Button>
        </Form.Group>
      </Col>
    </Row>
  );
}

export default CardObservatory;
