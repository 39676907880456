import { createSlice } from "@reduxjs/toolkit";

import { documentData } from "../actions/action";

const documentSlice = createSlice({
  name: "documents",
  initialState: {
    loading: false,
    documents: {},
  },
  extraReducers: (builder) => {
    builder.addCase(documentData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(documentData.fulfilled, (state, action) => {
      state.loading = false;
      state.documents = action.payload;
      state.error = "";
    });
    builder.addCase(documentData.rejected, (state, action) => {
      state.loading = false;
      state.documents = [];
      state.error = action.error.message;
    });
  },
});

export default documentSlice.reducer;
