import { createSlice } from "@reduxjs/toolkit";

import { juriCategoryDocumentData } from "../actions/action";

const juriCategoryDocumentSlice = createSlice({
  name: "juriCategoryDocuments",
  initialState: {
    loading: false,
    juriCategoryDocuments: {},
  },
  extraReducers: (builder) => {
    builder.addCase(juriCategoryDocumentData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(juriCategoryDocumentData.fulfilled, (state, action) => {
      state.loading = false;
      state.juriCategoryDocuments = action.payload;
      state.error = "";
    });
    builder.addCase(juriCategoryDocumentData.rejected, (state, action) => {
      state.loading = false;
      state.juriCategoryDocuments = [];
      state.error = action.error.message;
    });
  },
});

export default juriCategoryDocumentSlice.reducer;
