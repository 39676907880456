import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import "../App.css";
import "../theme.css";

const CardOpportunity = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (path.match("/opportunites")) {
      return <Pagination />;
    }
  };
  return (
    <div className="container py-11">
      {cardDetails === null && (
        <div className="row opportunity">
          {data?.map((item, index) => (
            <div className=" col-lg-12 mb-4" key={index}>
              <div className="bloc-opportunity">
                <h2>{item.title}</h2>
                <span>
                  Agence des Systèmes d'Information et du Numérique (ASIN)
                </span>
                <p>{item.description}</p>
                <a
                  className="link-fill"
                  onClick={() => setCardDetails(item)}
                  role="button"
                >
                  En savoir plus <FaChevronRight />
                </a>
              </div>
            </div>
          ))}
          {data?.length > 0 && <>{renderPagination()}</>}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
    </div>
  );
};

export default CardOpportunity;
