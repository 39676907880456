import React from "react";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";

const Author = () => {
  return (
    <>
      <Navbar />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Author;
