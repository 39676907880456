import React from "react";
import "../App.css";

import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import drapeau from "../assets/images/Drapeau.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid px-0">
        <div className="row flag">
          <div className="col-lg-12">
            <img src={drapeau} height="30" className="w-100" alt="Logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid ml-3 mr-3">
        <div className="row footer-top d-flex justify-content-center">
          <img
            src="/images/Logo_patrimonyme_white.svg"
            alt="logo"
            className="footer-logo"
            width="100%"
          />
        </div>
        <div className="bar"></div>

        <div className="row footer-bottom d-flex justify-content-between">
          <div className="col-lg-2 col-sm-6">
            <h5 className="footer-item-title">LE SECTEUR</h5>
            <ul className="footer-item">
              <li>
                <a href="#">RÉFORMES</a>
              </li>
              <li>
                <a href="#">L’ÉCOSYSTÈME AU TCHAD</a>
              </li>
              <li>
                <a href="#">PLATEFORMES</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6">
            <h5 className="footer-item-title">LE MINISTERE</h5>
            <ul className="footer-item">
              <li>
                <a href="#">LA MINISTRE</a>
              </li>
              <li>
                <a href="#">MISSION</a>
              </li>
              <li>
                <a href="#">ORGANIGRAMME</a>
              </li>
              <li>
                <a href="#">DIRECTIONS</a>
              </li>
              <li>
                <a href="#"> STRUCTURE SOUS TUTELLES</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6">
            <h5 className="footer-item-title">PROJETS</h5>
            <ul className="footer-item">
              <li>
                <a href="#">LA MINISTRE</a>
              </li>
              <li>
                <a href="#">MISSION</a>
              </li>
              <li>
                <a href="#">ORGANIGRAMME</a>
              </li>
              <li>
                <a href="#">DIRECTIONS</a>
              </li>
              <li>
                <a href="#"> STRUCTURE SOUS TUTELLES</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6">
            <h5 className="footer-item-title">PUBLICATIONS</h5>
            <ul className="footer-item">
              <li>
                <a href="#">ACTUALITÉS</a>
              </li>
              <li>
                <a href="#">DOCUMENTS</a>
              </li>
              <li>
                <a href="#">OPPORTUNITÉS</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6">
            <h5 className="footer-item-title">CONTACT</h5>
            <ul className="footer-item">
              <li>
                <a href="#">
                  <FaPhoneAlt /> 00135XXXXXXXXXXX
                </a>
              </li>
              <li>
                <a href="#">
                  <HiOutlineMail /> numerique.gouv.td
                </a>
              </li>
              <li>
                <a href="#">
                  <MdLocationOn /> Rue 662, N’Djamena
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="bar"></div>
      </div>

      <div className="row boc-icons justify-content-center">
        <div className="col-3 footer-item">
          <ul className="d-flex  align-items-center justify-content-between">
            <li className="social-media">
              <a href="#">
                <FaFacebookSquare size={30} color="#fff" />
              </a>
            </li>
            <li className="social-media">
              <a href="#">
                <FaTwitter size={30} color="#fff" />
              </a>
            </li>
            <li className="social-media">
              <a href="#">
                <FaInstagram size={30} color="#fff" />
              </a>
            </li>
            <li className="social-media">
              <a href="#">
                <FaLinkedin size={30} color="#fff" />
              </a>
            </li>
          </ul>
        </div>
        <p className="copyright">Tchad - 2024 - Mentions Légales</p>
      </div>
    </footer>
  );
};

export default Footer;
