import { createSlice } from "@reduxjs/toolkit";

import { realiseProjectData } from "../actions/action";

const realiseProjectSlice = createSlice({
  name: "realiseProjects",
  initialState: {
    loading: false,
    realiseProjects: {},
  },
  extraReducers: (builder) => {
    builder.addCase(realiseProjectData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(realiseProjectData.fulfilled, (state, action) => {
      state.loading = false;
      state.realiseProjects = action.payload;
      state.error = "";
    });
    builder.addCase(realiseProjectData.rejected, (state, action) => {
      state.loading = false;
      state.realiseProjects = [];
      state.error = action.error.message;
    });
  },
});

export default realiseProjectSlice.reducer;
