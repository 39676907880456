import React from "react";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import AddressCard from "../components/Address";

const Address = () => {
  return (
    <>
      <Navbar />
      <BannerCurrent title="Adresse du ministère" />
      <AddressCard />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Address;
