import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Decree = () => {
  let decree = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  let decrees = decree?.juriCategoryDocuments?.data?.document;

  console.log("laws: ", decree?.document);

  useEffect(() => {
    dispatch(juriCategoryDocumentData("aretes"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des Décrets" />
      <ExtraCard data={decrees} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Decree;
