import React, { useState } from "react";

import { useLocation } from "react-router-dom";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import { truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

import { FaChevronRight } from "react-icons/fa";
import FormationImg from "../assets/images/formation.png";

const TrainingCard = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (path.match("/formation")) {
      return <Pagination />;
    }
  };

  return (
    <div className="container">
      {cardDetails === null && (
        <div className="formation row mt-11">
          {data?.map((item, index) => (
            <div className=" grow-formation col-lg-12 mb-4" key={index}>
              <div className="row d-flex align-items-center">
                <div className="col-md-4">
                  <img src={FormationImg} className="img-fluid" alt="" />
                </div>
                <div className="col-md-8">
                  <span>{item?.category?.name}</span>
                  <h2>{item?.title}</h2>
                  <p>{truncate(item?.description, 200)}</p>
                  <a
                    className="link-fill"
                    onClick={() => setCardDetails(item)}
                    role="button"
                  >
                    Lire <FaChevronRight />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </div>
  );
};

export default TrainingCard;
