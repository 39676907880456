import React from "react";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ContactCard from "../components/ContactCard";

const ContactForm = () => {
  return (
    <>
      <Navbar />
      <BannerCurrent title="Contacts" />
      <ContactCard />
      <NewLetter />
      <Footer />
    </>
  );
};

export default ContactForm;
