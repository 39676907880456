import React from "react";
import { Facebook, Twitter, Linkedin, Envelope } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";

import Pagination from "./Pagination";

import { formatSimpleDate, truncate } from "../helpers/global_helper";

import document from "../assets/images/documentation.png";

import "../App.css";
import "../theme.css";

const ExtraCard = ({ data }) => {
  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/reformes") ||
      path.startsWith("/platformes") ||
      path.startsWith("/organisation-et-fonctionnement") ||
      path.startsWith("/plan-strategique-2030") ||
      path.startsWith("/plan-action-2024") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/rapport-activites")
    ) {
      return <Pagination />;
    }
  };

  return (
    <div className="container pt-11">
      <div className="box row">
        {data?.length === 0 && (
          <p className="text-center">
            Aucune donnée disponible pour le moment. Veuillez réessayer plus
          </p>
        )}

        {data?.map((elmt, index) => {
          let documentUrl = elmt?.media?.find(
            (media) =>
              media.mime_type === "application/pdf" ||
              media.mime_type === "application/msword" ||
              media.mime_type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );

          const renderBlock = () => {
            if (
              !(
                path.startsWith("/lois") ||
                path.startsWith("/decrets") ||
                path.startsWith("/arretes")
              )
            ) {
              return (
                <div className="author">
                  <span>Par : </span> {elmt?.author}
                </div>
              );
            }
          };

          return (
            <div className="col-lg-12 mb-4" key={index}>
              <div className="current-box">
                <div className="row">
                  <div className="col-5 list-info d-flex align-items-center">
                    <div className="img">
                      <img
                        src={document}
                        style={{ maxWidth: "50%" }}
                        className="img-fluid"
                        alt="Document"
                      />
                    </div>
                    <div className="infos-doc">
                      <div className="date">
                        <span>
                          Publié en {formatSimpleDate(elmt?.created_at)}
                        </span>
                      </div>
                      {renderBlock()}
                      <div className="size">
                        {elmt?.size} <span> ko</span>
                      </div>
                      <div className="views">
                        {elmt?.views_count} <span> Lectures</span>
                      </div>
                      <div className="download">
                        {elmt?.downloads_count} <span> Téléchargements</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="current-text">
                      <h2>{elmt?.title || elmt?.name}</h2>
                      <p className="mt-3">{truncate(elmt?.content, 80)}</p>
                    </div>
                    <div className="current-share d-flex align-items-center justify-content-between">
                      <div className="share d-flex align-items-center">
                        <span className="mr-3">Partager sur</span>
                        <ul>
                          <li>
                            <a href="">
                              <Facebook style={{ color: "#1877F2" }} />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <Twitter style={{ color: "#1DA1F2" }} />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <Linkedin style={{ color: "#0077b5" }} />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <Envelope />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="action">
                        <a className="mr-6" href={documentUrl?.original_url}>
                          Lire
                        </a>
                        <a href="#" download={documentUrl?.original_url}>
                          Télécharger
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default ExtraCard;
