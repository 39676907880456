import React from "react";
import "../App.css";
import "../theme.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const CardCurrent = () => {
  return (
    <Row className="mb-3">
      <Col lg={5}>
        <Form.Group controlId="formGridText">
          <Form.Label>Mots clés</Form.Label>
          <Form.Control
            type="text"
            placeholder="Saisissez les mots clés"
            className="w-100"
          />
        </Form.Group>
      </Col>

      <Col lg={5}>
        <Form.Group controlId="formGridDate">
          <Form.Label>Année</Form.Label>
          <Form.Control type="date" placeholder="aaa" className="w-100" />
        </Form.Group>
      </Col>

      <Col lg={2} className="">
        <Form.Group controlId="formGridSubmit">
          <Button type="submit">Rechercher</Button>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CardCurrent;
