import { createSlice } from "@reduxjs/toolkit";

import { economiePromotionData } from "../actions/action";

const economiePromotionSlice = createSlice({
  name: "economiePromotion",
  initialState: {
    loading: false,
    economiePromotions: {},
  },
  extraReducers: (builder) => {
    builder.addCase(economiePromotionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(economiePromotionData.fulfilled, (state, action) => {
      state.loading = false;
      state.economiePromotions = action.payload;
      state.error = "";
    });
    builder.addCase(economiePromotionData.rejected, (state, action) => {
      state.loading = false;
      state.economiePromotions = [];
      state.error = action.error.message;
    });
  },
});

export default economiePromotionSlice.reducer;
