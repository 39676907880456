import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import MediumExtraCard from "../components/MediumExtraCard";

import { economiePromotionData } from "../redux/actions/action";

const EconomiquePromotion = () => {
  let economiquePromotion = useSelector((state) => state.economiquePromotion);
  const dispatch = useDispatch();

  console.log("economiquePromotion: ", economiquePromotion);

  let economiquePromotions = economiquePromotion?.economiePromotions?.data;

  useEffect(() => {
    dispatch(economiePromotionData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Promotion Economie Numérique" />
      <MediumExtraCard data={economiquePromotions} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default EconomiquePromotion;
