import React from "react";
import "../App.css";
import "../theme.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const ContactCard = () => {
  return (

    <div className="container pt-11">
      <div className="row">
        <div className="offset-1 col-lg-10">
          <div className="box-contact">
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="formGridText" className="mb-6">
                  <Form.Label>Civilité *</Form.Label>
                  <Form.Select className="w-100">
                    <option className="text-white">Mlle.</option>
                    <option value="1">Mr.</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Objet*</Form.Label>
                  <Form.Control type="text" placeholder="Objet" className="w-100" />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Nom*</Form.Label>
                  <Form.Control type="text" placeholder="Nom" className="w-100" />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Prénom*</Form.Label>
                  <Form.Control type="text" placeholder="Prenom" className="w-100" />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control type="mail" placeholder="Email" className="w-100" />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Téléphone*</Form.Label>
                  <Form.Control type="phone" placeholder="Téléphone" className="w-100" />
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group controlId="formGridDate">
                  <Form.Label>Message*</Form.Label>
                  <Form.Control as="textarea" placeholder="Votre message" className="w-100" style={{ height: "200px" }}  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="text-end">
                <Form.Group controlId="formGridSubmit">
                  <Button type="submit" className="w-100">
                    Envoyer
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ContactCard;
