import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import CardOpportunity from "../components/CardOpportunity";

import { opportunityData } from "../redux/actions/action";

const Opportunity = () => {
  let opportunity = useSelector((state) => state.opportunity);
  const dispatch = useDispatch();

  console.log("opportunity: ", opportunity);

  let opportunities = opportunity?.opportunities?.data;

  useEffect(() => {
    dispatch(opportunityData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Opportunités" />
      <CardOpportunity data={opportunities} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Opportunity;
