import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function TabsComponent({ activeTab, handleTabChange }) {
  return (
    <Tabs
      id="fill-tab-example"
      activeKey={activeTab}
      onSelect={(eventKey) => handleTabChange(eventKey)}
      className="justify-content-center me-auto"
    >
      <Tab eventKey="texte" title="Texte"></Tab>
      <Tab eventKey="document" title="Document"></Tab>
    </Tabs>
  );
}

export default TabsComponent;

