import { createSlice } from "@reduxjs/toolkit";

import { actualityData } from "../actions/action";

const actualitySlice = createSlice({
  name: "actuality",
  initialState: {
    loading: false,
    actualities: {},
  },
  extraReducers: (builder) => {
    builder.addCase(actualityData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(actualityData.fulfilled, (state, action) => {
      state.loading = false;
      state.actualities = action.payload;
      state.error = "";
    });
    builder.addCase(actualityData.rejected, (state, action) => {
      state.loading = false;
      state.actualities = [];
      state.error = action.error.message;
    });
  },
});

export default actualitySlice.reducer;
