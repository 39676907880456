import { createSlice } from "@reduxjs/toolkit";

import { organigramData } from "../actions/action";

const organigramSlice = createSlice({
  name: "organigrams",
  initialState: {
    loading: false,
    organigrams: {},
  },
  extraReducers: (builder) => {
    builder.addCase(organigramData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(organigramData.fulfilled, (state, action) => {
      state.loading = false;
      state.organigrams = action.payload;
      state.error = "";
    });
    builder.addCase(organigramData.rejected, (state, action) => {
      state.loading = false;
      state.organigrams = [];
      state.error = action.error.message;
    });
  },
});

export default organigramSlice.reducer;
