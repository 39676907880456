import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, slug, config = {}) {
  try {
    let apiUrl;
    if (slug) {
      apiUrl = `${API_URL}${url}/${slug}`;
    } else {
      apiUrl = `${API_URL}${url}`;
    }
    const response = await axiosApi.get(apiUrl, {
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error("Error in get request:", error);
    throw error;
  }
}

export async function post(url, data, config = {}) {
  try {
    const response = await axiosApi.post(url, { ...data }, { ...config });
    return response.data;
  } catch (error) {
    console.error("Error in post request:", error);
    throw error;
  }
}

export async function put(url, data, config = {}) {
  try {
    const response = await axiosApi.put(url, { ...data }, { ...config });
    return response.data;
  } catch (error) {
    console.error("Error in put request:", error);
    throw error;
  }
}

export async function del(url, config = {}) {
  try {
    const response = await axiosApi.delete(url, { ...config });
    return response.data;
  } catch (error) {
    console.error("Error in delete request:", error);
    throw error;
  }
}
