import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import TrainingCard from "../components/CardFormation";

import { trainingData } from "../redux/actions/action";

const Training = () => {
  let training = useSelector((state) => state.training);
  const dispatch = useDispatch();

  let trainings = training?.trainings?.data;

  useEffect(() => {
    dispatch(trainingData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Formation" />
      <TrainingCard data={trainings} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Training;
