import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Arrete = () => {
  let arrete = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  let arretes = arrete?.juriCategoryDocuments?.data?.document;

  console.log("laws: ", arrete?.document);

  useEffect(() => {
    dispatch(juriCategoryDocumentData("aretes"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des Arrêtés" />
      <ExtraCard data={arretes} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Arrete;
