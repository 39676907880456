const NavbarItems = [
  {
    label: "Le secteur",
    items: [
      {
        label: "Etat des lieux",
        path: "/etat-des-lieux",
      },
      {
        label: "Acteur",
        icon: "pi pi-palette",
        items: [
          {
            label: "OTM",
            path: "#",
          },
          {
            label: "FAI",
            path: "#",
          },
          {
            label: "ANSICE",
            path: "#",
          },
          {
            label: "Autres",
            path: "#",
          },
        ],
      },
      {
        label: "Cardre juridique et institutionnel",
        icon: "pi pi-palette",
        items: [
          {
            label: "LOIS",
            path: "/lois",
          },
          {
            label: "DECRETS",
            path: "decrets",
          },
          {
            label: "ARETES",
            path: "/arretes",
          },
          {
            label: "OBSERVATOIRES",
            path: "/observatoires",
          },
        ],
      },
      {
        label: "Réformes",
        path: "/reformes",
      },
      {
        label: "Ecosystème au Tchad",
        path: "/ecosysteme-du-tchad",
      },
      {
        label: "Plateformes",
        path: "/platformes",
      },
    ],
  },
  {
    label: "MTEN",
    items: [
      {
        label: "Le Ministre",
        icon: "pi pi-palette",
        items: [
          {
            label: "Biographie",
            path: "/biographie",
          },
          {
            label: "Mot du ministre",
            path: "/mot-du-ministre",
          },
          {
            label: "Agenda du ministre",
            path: "/agenda-du-ministre",
          },
          {
            label: "Archives du ministère",
            path: "/archive-du-ministere",
          },
          {
            label: "Organigramme du ministère",
            path: "/organigramme-du-ministere",
          },
          {
            label: "Directions sous tutelles",
            path: "/directions-sous-tutelles",
          },
        ],
      },
      {
        label: "A propos",
        icon: "pi pi-palette",
        items: [
          {
            label: "Visions",
            path: "/visions",
          },
          {
            label: "Missions du ministère",
            path: "/missions-du-ministere",
          },
          {
            label: "Objectifs",
            path: "/objectifs",
          },
        ],
      },
      {
        label: "Organisations et fonctionnements",
        path: "/organisation-et-fonctionnement",
      },
      {
        label: "Entites sous tutelles",
        icon: "pi pi-palette",
        items: [
          {
            label: "ARCEP",
            path: "#",
          },
          {
            label: "ADETIC",
            path: "#",
          },
          {
            label: "STPE",
            path: "#",
          },
          {
            label: "SOTEL",
            path: "#",
          },
          {
            label: "ENASTIC",
            path: "#",
          },
          {
            label: "SAFITEL",
            path: "#",
          },
          {
            label: "Autres",
            path: "#",
          },
        ],
      },
    ],
  },
  {
    label: "Projets",
    items: [
      {
        label: "Plan stratégique 20-30",
        path: "/plan-strategique-2030",
      },
      {
        label: "Plan d'action 2024",
        path: "/plan-action-2024",
      },
      {
        label: "Projets réalisés",
        path: "/projets-realises",
      },
      {
        label: "Projets en cours de réalisations",
        path: "/projet-encour-de-realisation",
      },
    ],
  },
  {
    label: "Communications",
    items: [
      {
        label: "Actualités",
        path: "/actualites",
      },
      {
        label: "Rapports d'activités",
        path: "/rapport-activites",
      },
      {
        label: "Documentation",
        path: "/documentation",
      },
      {
        label: "Opportunités",
        path: "/opportunites",
      },
    ],
  },
  {
    label: "Economie numérique",
    items: [
      {
        label: "Startups",
        path: "/start-up",
      },
      {
        label: "Promotions économique numérique",
        path: "/promotion-economique-numerique",
      },
      {
        label: "Sensibilisation",
        path: "/sensibilisation",
      },
      {
        label: "Formation",
        path: "/formation",
      },
    ],
  },
  {
    label: "Agenda",
    path: "/agenda",
  },
  {
    label: "Contact",
    items: [
      {
        label: "Adresse du ministère",
        path: "/address-ministere",
      },

      {
        label: "Ecrire au ministère",
        path: "/contact-ministere",
      },
    ],
  },
];

export { NavbarItems };
