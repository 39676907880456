import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Observatory = () => {
  let observatoire = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  let observatoires = observatoire?.juriCategoryDocuments?.data?.document;

  let observatoireText = observatoires?.filter(
    (observatoire) => observatoire.type === "text"
  );

  let observatoireDocument = observatoires?.filter(
    (observatoire) => observatoire.type === "document"
  );

  console.log("observatoires: ", observatoires);

  useEffect(() => {
    dispatch(juriCategoryDocumentData("observatoire"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Observatoire" />
      <GlobalCard
        textData={observatoireText}
        documentData={observatoireDocument}
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Observatory;
