import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { FaChevronRight } from "react-icons/fa";

import Pagination from "./Pagination";
import SingleDetailPage from "./SingleDetailsPage";

import { RenderButtonTitle } from "../utils/const";

import "../App.css";
import "../theme.css";

import ProjectImage from "../assets/images/project.png";

import {
  formatSimpleDate,
  formatDate,
  truncate,
} from "../helpers/global_helper";

const SimpleCard = ({ data }) => {
  const [cardDetails, setCardDetails] = useState(null);

  const location = useLocation();
  const path = location.pathname;

  let renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/reformes") ||
      path.startsWith("/agenda-du-ministre") ||
      path.startsWith("/organisation-et-fonctionnement") ||
      path.startsWith("/plan-strategique-2030") ||
      path.startsWith("/plan-action-2024") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/projet-encour-de-realisation") ||
      path.startsWith("/actualites") ||
      path.startsWith("/rapport-activites") ||
      path.startsWith("/start-up")
    ) {
      return <Pagination />;
    }
  };

  return (
    <>
      {cardDetails === null && (
        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          {data?.length === 0 && (
            <p className="text-center pt-11">
              Aucune donnée disponible pour le moment. Veuillez réessayer plus
            </p>
          )}
          {data?.map((elemt, index) => {
            let renderAuthorBlock = () => {
              if (
                path.startsWith("/etat-des-lieux") ||
                path.startsWith("/reformes") ||
                path.startsWith("/organisation-et-fonctionnement") ||
                path.startsWith("/plan-action-2024") ||
                path.startsWith("/start-up")
              ) {
                return (
                  <div>
                    <h6 className="pb-2 d-inline">
                      <span>Par:</span> {elemt?.author}
                    </h6>
                  </div>
                );
              }
            };

            let renderPublicationBlock = () => {
              if (
                path.startsWith("/etat-des-lieux") ||
                path.startsWith("/reformes") ||
                path.startsWith("/organisation-et-fonctionnement") ||
                path.startsWith("/plan-action-2024") ||
                path.startsWith("/rapport-activites")
              ) {
                return (
                  <div>
                    <h6 className="pb-2 d-inline">
                      <span> Publié en</span>{" "}
                      {formatSimpleDate(elemt.created_at)}
                    </h6>
                  </div>
                );
              } else if (path.startsWith("/start-up")) {
                return (
                  <div>
                    <h6 className="pb-2 d-inline">
                      <span>Crée le</span> {formatSimpleDate(elemt.created_at)}
                    </h6>
                  </div>
                );
              } else if (path.startsWith("/")) {
                return (
                  <div>
                    <h6 className="pb-2 d-inline">
                      {formatDate(elemt.created_at)}
                    </h6>
                  </div>
                );
              }
            };

            let renderNouvelleBlock = () => {
              if (
                !(
                  path.startsWith("/etat-des-lieux") ||
                  path.startsWith("/reformes") ||
                  path.startsWith("/organisation-et-fonctionnement") ||
                  path.startsWith("/plan-action-2024") ||
                  path.startsWith("/rapport-activites")
                )
              ) {
                return <span className="category">Nouvelle</span>;
              }
            };

            let imageUrl = elemt?.media?.find(
              (media) =>
                media.mime_type === "image/jpeg" ||
                media.mime_type === "image/png" ||
                media.mime_type === "image/jpg"
            );

            return (
              <div className="col-lg-4 mb-4 mt-7" key={index}>
                <div className="project-single">
                  <div className="project-box">
                    <div className="project-img">
                      <img
                        src={imageUrl?.original_url || ProjectImage}
                        height="100px"
                        className="img-fluid pb-2 img-container"
                        alt="Project"
                      />
                      {renderNouvelleBlock()}
                    </div>
                    <div className="project-title">
                      <div className="d-flex align-items-center justify-content-between py-2">
                        {renderAuthorBlock()}
                        {renderPublicationBlock()}
                      </div>
                      <h2>{truncate(elemt?.title, 25)}</h2>
                      <div className="d-flex align-items-center justify-content-between">
                        {path.startsWith("/rapport-activites") && (
                          <div>Secteur: {elemt?.secteur_activite}</div>
                        )}
                        {path.startsWith("/rapport-activites") && (
                          <div>Période: {elemt?.periode}</div>
                        )}
                      </div>
                      <p className="py-3">{truncate(elemt?.content, 80)}</p>
                      <a
                        className="btn-project"
                        onClick={() => path !== "/" && setCardDetails(elemt)}
                        role="button"
                      >
                        {RenderButtonTitle()} <FaChevronRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {cardDetails !== null && <SingleDetailPage data={cardDetails} />}
      {cardDetails === null && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </>
  );
};

export default SimpleCard;
