import { createSlice } from "@reduxjs/toolkit";

import { strategiquePlanData } from "../actions/action";

const strategiquePlanSlice = createSlice({
  name: "strategiquePan",
  initialState: {
    loading: false,
    strategiquePlans: {},
  },
  extraReducers: (builder) => {
    builder.addCase(strategiquePlanData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(strategiquePlanData.fulfilled, (state, action) => {
      state.loading = false;
      state.strategiquePlans = action.payload;
      state.error = "";
    });
    builder.addCase(strategiquePlanData.rejected, (state, action) => {
      state.loading = false;
      state.strategiquePlans = [];
      state.error = action.error.message;
    });
  },
});

export default strategiquePlanSlice.reducer;
