import React from "react";
import { Envelope, PinMapFill, Phone } from "react-bootstrap-icons";

import "../App.css";
import "../theme.css";

import CardMap from "../assets/images/address.png";

const AddressCard = () => {
  return (
    <div className="container pt-11 pb-7">
      <div className="infos-address row">
        <div className="bloc-address col-lg-4 col-12">
          <div className="">
            <div className="head mb-4">
              <span className="icon">
                <PinMapFill className="mr-2" />
              </span>
              <span>Adresse</span>
            </div>
            <p>368, Avenue Pape Jean Paul II 01BP 302 ; N’Djamena  </p>
          </div>
        </div>

        <div className="bloc-address col-lg-4 col-12">
          <div className="">
            <div className="head mb-4">
              <span className="icon">
                <Envelope className="mr-2" />
              </span>
              <span>Email</span>
            </div>
            <p>ministere.gouv.td</p>
          </div>
        </div>

        <div className="bloc-address col-lg-4 col-12">
          <div className="">
            <div className="head mb-4">
              <span className="icon">
                <Phone className="mr-2" />
              </span>
              <span>Adresse</span>
            </div>
            <p>00135XXXXXXXXXXX</p>
          </div>
        </div>
      </div>
      <div className="row mt-7">
        <div className="col-12">
          <img src={CardMap} className="w-100" alt="Project" />
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
