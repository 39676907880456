import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import AgendaCard from "../components/CardAgenda";
import SinglelPage from "../components/SingleDetailsPage";
import Organigrame from "../components/Organigramme";
import CardTutelle from "../components/CardDirTutelle";
import ArchiveCard from "../components/ArchiveCard";

import {
  ministerBiographyData,
  ministerAgendaData,
  directionUnderTutellData,
  ministerArchiveData,
} from "../redux/actions/action";

const Minister = () => {
  let ministerBiography = useSelector((state) => state.ministerBiography);
  let ministerAgenda = useSelector((state) => state.ministerAgenda);
  let ministerArchive = useSelector((state) => state.ministerArchive);

  let directionsSousTutelles = useSelector(
    (state) => state.directionUndertutelle
  );

  const dispatch = useDispatch();
  const location = useLocation();

  let ministerBiographyInfo = ministerBiography?.ministerBiographies?.data;
  let ministerAgendaInfo = ministerAgenda?.ministerAgenda?.data;
  let ministerArchiveInfo = ministerArchive?.ministerArchive?.data;
  let directionsSousTutellesInfo =
    directionsSousTutelles?.directionUnderTutell?.data;

  React.useEffect(() => {
    dispatch(ministerBiographyData());
    dispatch(ministerAgendaData());
    dispatch(directionUnderTutellData());
    dispatch(ministerArchiveData());
  }, [dispatch]);

  const path = location.pathname;

  const getTitle = () => {
    if (path.startsWith("/biographie")) {
      return "Biographie";
    } else if (path.startsWith("/mot-du-ministre")) {
      return;
    } else if (path.startsWith("/agenda-du-ministre")) {
      return "Agenda du ministre";
    } else if (path.startsWith("/organigramme-du-ministere")) {
      return "Organigramme du ministère";
    } else if (path.startsWith("/archive-du-ministere")) {
      return "Archives du ministère";
    } else if ("/directions-sous-tutelles") {
      return "Directions sous tutelles";
    }
    return "";
  };

  return (
    <>
      <Navbar />
      <BannerCurrent title={getTitle()} />
      {path.startsWith("/mot-du-ministre") && (
        <SinglelPage data={ministerBiographyInfo} />
      )}
      {path.startsWith("/biographie") && (
        <SinglelPage data={ministerBiographyInfo} />
      )}
      {path.startsWith("/agenda-du-ministre") && (
        <AgendaCard data={ministerAgendaInfo} />
      )}
      {path.startsWith("/archive-du-ministere") && (
        <ArchiveCard data={ministerArchiveInfo} />
      )}
      {path.startsWith("/organigramme-du-ministere") && <Organigrame />}
      {path.startsWith("/directions-sous-tutelles") && (
        <CardTutelle data={directionsSousTutellesInfo} />
      )}
      <NewLetter />
      <Footer />
    </>
  );
};

export default Minister;
