import { createSlice } from "@reduxjs/toolkit";

import { ministerAgendaData } from "../actions/action";

const ministerAgendaSlice = createSlice({
  name: "ministerAgenda",
  initialState: {
    loading: false,
    ministerAgenda: {},
  },
  extraReducers: (builder) => {
    builder.addCase(ministerAgendaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ministerAgendaData.fulfilled, (state, action) => {
      state.loading = false;
      state.ministerAgenda = action.payload;
      state.error = "";
    });
    builder.addCase(ministerAgendaData.rejected, (state, action) => {
      state.loading = false;
      state.ministerAgenda = [];
      state.error = action.error.message;
    });
  },
});

export default ministerAgendaSlice.reducer;
