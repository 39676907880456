import { configureStore } from "@reduxjs/toolkit";

import projectReducer from "./reducers/projectReducer";
import currentSituation from "./reducers/currentSituationReducer";
import actualityReducer from "./reducers/actualityReducer";
import actorReducer from "./reducers/actorReducer";
import plateformeReducer from "./reducers/plateformeReducer";
import agendaReducer from "./reducers/agendaReducer";
import reformeReducer from "./reducers/reformeReducer";
import organigramReducer from "./reducers/organigramReducer";
import managmentUnderSuperVisionReducer from "./reducers/managmentUnderSuperVisionReducer";
import aboutUsReducer from "./reducers/aboutUsReducer";
import entitieReducer from "./reducers/entitieReducer";
import strategiquePanReducer from "./reducers/strategiquePanReducer";
import actionPlanReducer from "./reducers/actionPlanReducer";
import realiseProjectReducer from "./reducers/realiseProjectReducer";
import repportReducer from "./reducers/repportReducer";
import documentReducer from "./reducers/documentReducer";
import startupReducer from "./reducers/startupReducer";
import promotionReducer from "./reducers/promotionReducer";
import juriDocumentReducer from "./reducers/juriDocumentReducer";
import juriCatgoryDocumentReducer from "./reducers/juriCatgoryDocumentReducer";
import organismAndFonctionalityReducer from "./reducers/organismAndFonctionalityReducer";
import globalProjectReducer from "./reducers/globalProjectReducer";
import globalActualityReducer from "./reducers/globalActualityReducer";
import tchadEcosystemReducer from "./reducers/tchadEcosystemReducer";
import ministerBiographyReducer from "./reducers/ministerBiographyReducer";
import ministerAgendaReducer from "./reducers/ministerAgendaReducer";
import ministerArchiveReducer from "./reducers/ministerArchiveReducer";
import directionUndertutelleReducer from "./reducers/directionUndertutelleReducer";
import opportunityReducer from "./reducers/opportunityReducer";
import sensibilisationReducer from "./reducers/sensibilisationReducer";
import trainingReducer from "./reducers/trainingReducer";
import economiquePromotionReducer from "./reducers/economiquePromotionReducer";

const store = configureStore({
  reducer: {
    project: projectReducer,
    currentSituation: currentSituation,
    actuality: actualityReducer,
    actor: actorReducer,
    plateforme: plateformeReducer,
    agenda: agendaReducer,
    reforme: reformeReducer,
    organigram: organigramReducer,
    managmentUnderSuperVision: managmentUnderSuperVisionReducer,
    aboutUs: aboutUsReducer,
    entity: entitieReducer,
    strategiquePan: strategiquePanReducer,
    actionPlan: actionPlanReducer,
    realiseProject: realiseProjectReducer,
    repport: repportReducer,
    document: documentReducer,
    startup: startupReducer,
    promotion: promotionReducer,
    juriDocument: juriDocumentReducer,
    juriCategoryDocument: juriCatgoryDocumentReducer,
    organismAndFonctionality: organismAndFonctionalityReducer,
    globalProject: globalProjectReducer,
    globalActuality: globalActualityReducer,
    tchadEcosystem: tchadEcosystemReducer,
    ministerBiography: ministerBiographyReducer,
    ministerAgenda: ministerAgendaReducer,
    ministerArchive: ministerArchiveReducer,
    directionUndertutelle: directionUndertutelleReducer,
    opportunity: opportunityReducer,
    sensibilisation: sensibilisationReducer,
    training: trainingReducer,
    economiquePromotion: economiquePromotionReducer,
  },
});

export default store;
