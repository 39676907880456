import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const StrategiquePlan2030 = () => {
  let strategiquePlan = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();

  console.log("strategiquePlan: ", strategiquePlan);

  let strategiquePlans = strategiquePlan?.globalProjects?.data;

  // let strategiquePlansDocument = strategiquePlans?.filter(
  //   (item) => item.media.length === 0
  // );

  // let strategiquePlansText = strategiquePlans?.filter(
  //   (item) => item.media.length > 0
  // );

  React.useEffect(() => {
    dispatch(globalProjectData("plan-strategique-2030"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Plans stratégiques 20-30" />
      <GlobalCard textData={strategiquePlans} documentData={strategiquePlans} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default StrategiquePlan2030;
