import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { currentSituationData } from "../redux/actions/action";

const CurrentSituation = () => {
  let currentSituation = useSelector((state) => state.currentSituation);
  const dispatch = useDispatch();

  let currentSituationsData = currentSituation.currentSituations.data;

  let currentSituationDocument = currentSituationsData?.filter(
    (item) => item.type === "text"
  );

  let currentSituationText = currentSituationsData?.filter(
    (item) => item.type === "document"
  );

  React.useEffect(() => {
    dispatch(currentSituationData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Etats des lieux" />
      <GlobalCard
        textData={currentSituationText}
        documentData={currentSituationDocument}
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default CurrentSituation;
