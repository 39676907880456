import { createSlice } from "@reduxjs/toolkit";

import { actorData } from "../actions/action";

const actorSlice = createSlice({
  name: "actors",
  initialState: {
    loading: false,
    actors: {},
  },
  extraReducers: (builder) => {
    builder.addCase(actorData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(actorData.fulfilled, (state, action) => {
      state.loading = false;
      state.actors = action.payload;
      state.error = "";
    });
    builder.addCase(actorData.rejected, (state, action) => {
      state.loading = false;
      state.actors = [];
      state.error = action.error.message;
    });
  },
});

export default actorSlice.reducer;
